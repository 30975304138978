.container {
  background-size: cover;
  background-position: 100% center;
}

.titleContainer {
    display: flex;
    height: 100%;
    background-color: rgba(65, 65, 65, 0.6);
    padding-top: 43px;
}
  
  .titleWrapper {
    padding: 1em;
    margin: auto;
    color: white;
}

.title {
  font-weight: 500;
  font-size: 2.5em;
}

@media screen and (max-width: 1070px) {
  .titleContainer {
    padding: 1em 0;
  }

  .titleWrapper {
    text-align: center;
  }

  .title {
    font-size: 2em;
  }
}