.continuing-education-requirements-title-container {
    background: url("../../../assets/subpage-titles/ce-banner.jpg");
    background-size: cover;
    background-position: 100% 20%;
}

.dentists {
  padding-bottom: 3em;
  border-bottom: solid 5px var(--gold);
}

.assistants {
  margin-top: 3em;
}

.float-img {
  height: 200px;
  width: 200px;
  float: right;
  object-fit: cover;
  margin:  0 0 1em 1em;
}

@media screen and (max-width: 1000px) {
  .float-img {
    float: unset;
    margin: 0;
  }
}