.box {
    padding: 1em 0;
    border-bottom: solid 1px lightgrey;
    font-family: "Poppins", sans-serif;
}

.box:first-child {
    padding-top: 0;
}

.box:last-child {
    border-bottom: none;
}