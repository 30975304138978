.container {
    padding-top: 1em;
}

.title {
    text-align: center;
}

.container img {
    width: 75px;
    display: block;
    margin: 2em auto;
}

@media screen and (max-width: 1040px) {
    .container {
        border-top: solid 1px lightgrey;
    }
}