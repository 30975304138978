.article {
    display: flex;
    min-height: 100vh;
    padding: 2em;
    position: relative;
  }

  @media screen and (max-width: 1215px) {
    .article {
      padding: 1em;
    }
  }

  @media screen and (max-width: 1030px) {
    .article {
      flex-direction: column;
    }
  }