.search-dentists {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 170px;
    padding: .6em 1em 0 0;
    font-weight: 500;
    background-color: white;
}

.speciality-select, .city-select, .name-input {
    margin: 1em 0;
    font-size: 16px;
}

.search-title {
    margin-top: 0;
    padding-bottom: .5em;
    border-bottom: solid 1px lightgrey;
}

.name-form {
    display: flex;
    flex-direction: column;
}

.name-label {
    font-size: .8em;
}

.name-input {
    height: 30px;
    border: solid 1px lightgrey;
    font-family: "Poppins", sans-serif;
}

.search-reset-btn {
    margin-top: 1em;
    padding: .2em .4em;
    border: solid 1px lightgrey;
    width: fit-content;
}

.search-reset-btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 1030px) {
    .search-dentists {
        width: 75%;
        margin: auto;
    }

    .name-input {
        text-align: center;
    }
}