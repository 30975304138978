@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
.footer {
    display: flex;
    justify-content: space-between;
    height: -webkit-min-content;
    height: min-content;
    padding: .3em 2.5em;
    font-size: 13px;
    font-weight: 400;
    color: white;
    background-color: #595959;
    font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 620px) {
    .footer {
        flex-direction: column;
        text-align: center;
        padding: .3em;
    }

    .legal-privacy {
        padding-bottom: .2em;
    }
}
.Section_section__2qxLy {
    width: 80%;
    padding: 0 1em 0 2em;
    line-height: 2em;
    box-sizing: border-box;
    overflow: hidden;
}

.Section_section__2qxLy h1 {
    color: #595959;
}

.Section_section__2qxLy a {
    font-weight: 500;
    color: var(--light-green);
}

.Section_section__2qxLy button {
    border: solid 1px lightgrey;
}

.Section_section__2qxLy figure {
    margin: 0;
}

.Section_section__2qxLy table {
    width: 100%;
    margin: 2em 0;
    text-align: center;
    border-collapse: collapse;
}

.Section_section__2qxLy table thead th {
    padding: .3em 1em;
}

.Section_section__2qxLy table thead th:first-of-type {
    border-radius: 5px 0 0 0;
}

.Section_section__2qxLy table thead th:last-of-type {
    border-radius: 0 5px 0 0;
}

.Section_section__2qxLy table th {
    background-color: var(--light-green);
    color: white;
    font-weight: 600;
}

.Section_section__2qxLy table tr:nth-child(even) {
    background-color: var(--offwhite);
}

.Section_section__2qxLy table td {
    padding:  1.5em 1em;
    text-align: left;
    color: black;
}

@media screen and (max-width: 1030px) {
    .Section_section__2qxLy {
        width: 100%;
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .Section_section__2qxLy {
        width: 100%;
        padding: 0;
    }

    .Section_section__2qxLy table th {
        display: none;
    }

    .Section_section__2qxLy table tr {
        display: flex;
        flex-direction: column;
        margin: 15px;
    }

    .Section_section__2qxLy table tr td:first-child {
        background-color: #595959;
        color: white;
    }
}
.spinner-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.spinner {
    margin-top: 10%;
}
.content-cms {
    width: 80%;
    padding: 0 1em 0 2em;
    line-height: 2em;
    box-sizing: border-box;
    overflow: hidden;
}
  
.content-cms h1 {
    color: #595959;
}
.content-cms h2 {
    color: #595959;
}
.content-cms h3 {
    color: #595959;
}
.content-cms h4 {
    color: #595959;
}

.content-cms a {
    color: var(--light-green);
    font-weight: 600;
}

.content-cms button {
    border: solid 1px lightgrey;
}

.content-cms figure {
    margin: 0;
}

.content-cms table {
    width: 100%;
    margin: 2em 0;
    text-align: center;
    border-collapse: collapse;
}

.content-cms table th {
    background-color: var(--light-green);
    color: white;
    font-weight: 600;
}

.content-cms table tr:nth-child(even) {
    background-color: var(--offwhite);
}

.content-cms table td {
    padding: 1em;
    text-align: left;
    color: black;
}

@media screen and (max-width: 1030px) {
    .content-cms {
        width: 100%;
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .content-cms {
        width: 100%;
        padding: 0;
    }

    .content-cms table th {
        display: none;
    }

    .content-cms table tr {
        display: flex;
        flex-direction: column;
        margin: 15px;
    }

    .content-cms table tr td:first-child {
        background-color: var(--dark-blue);
        color: white;
    }
}
.subpage-posts {
    width: 80%;
    padding: 0 1em 0 2em;
    line-height: 2em;
    overflow: hidden;
}

.post-box {
    padding: 1em 0;
    border-bottom: solid 1px lightgrey;
}

.post-box:first-child {
    padding-top: 0;
}

.post-box:last-child {
    border-bottom: none;
}

.subpage-posts h2 {
    color: #595959;
}

@media screen and (max-width: 1030px) {
    .subpage-posts {
        width: 100%;
        padding: 0;
    }
}
.Title_container__FaYUQ {
  background-size: cover;
  background-position: 100% center;
}

.Title_titleContainer__1E_u8 {
    display: flex;
    height: 100%;
    background-color: rgba(65, 65, 65, 0.6);
    padding-top: 43px;
}
  
  .Title_titleWrapper__16YD4 {
    padding: 1em;
    margin: auto;
    color: white;
}

.Title_title__szi3z {
  font-weight: 500;
  font-size: 2.5em;
}

@media screen and (max-width: 1070px) {
  .Title_titleContainer__1E_u8 {
    padding: 1em 0;
  }

  .Title_titleWrapper__16YD4 {
    text-align: center;
  }

  .Title_title__szi3z {
    font-size: 2em;
  }
}
.Article_article__eH_C8 {
    display: flex;
    min-height: 100vh;
    padding: 2em;
    position: relative;
  }

  @media screen and (max-width: 1215px) {
    .Article_article__eH_C8 {
      padding: 1em;
    }
  }

  @media screen and (max-width: 1030px) {
    .Article_article__eH_C8 {
      flex-direction: column;
    }
  }
.Main_main__2IrYU {
  margin: 0 auto;
  min-height: 100vh;
  margin-top: 107px;
  max-width: 1500px;
}

@media screen and (max-width: 1070px) {
  .Main_main__2IrYU{
    margin-top: 75.81px;
  }
}

@media screen and (max-width: 500px) {
  .Main_main__2IrYU{
    margin-top: 57.88px;
  }
}



.sidebar {
    width: 20%;
    border-right: solid 1px lightgrey;
    padding-right: 2em;
    box-sizing: border-box;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
  }
  
  .sidebar-header {
    margin: 0;
    padding: 1.2em 0 5px 0;
    font-weight: 600;
    font-size: 1.3em;
    color: #333;
    border-bottom: solid 1px #595959;
  }

  .sidenav {
    border-bottom: solid 1px #595959;
  }
  
  .sidenav > .active {
    color: #333;
    font-weight: 600;
  }
  
  .sidenav > li {
    margin: 1em 0;
    font-size: 14px;
    position: relative;
  }

  .sidenav > li:hover {
    cursor: pointer;
  }
  
  .sidebar-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6em 0;
    font-size: 15px;
  }
  
  .sidebar-box svg {
    width: 65px;
    height: 65px;
    padding-bottom: 10px;
  }

  .mobile-subpage-select {
    display: none;
    text-align: center;
  }

  @media screen and (max-width: 1030px) {
    .sidebar {
      width: 100%;
      text-align: center;
      border-right: none;
      padding: 0;
      font-size: 16px;
    }

    .sidebar-header {
      display: none;
    }

    .sidenav-container {
      display: none;
      border-bottom: none;
    }

    .mobile-subpage-select {
      display: block;
    }
  }
.Applications_container__2MgMs {
  padding-top: .5em;
}

.Applications_renewContainer__3B7Ui {
  padding-bottom: .5em;
}

.Applications_openInNew__1Dzte, .Applications_pdf__XvGTp {
  fill: grey;
  vertical-align: middle;
}

.Applications_openInNew__1Dzte {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}

.Applications_pdf__XvGTp {
  width: 20px;
  height: 20px;
}

.Applications_title__3bqYX {
  color: var(--light-green);
  margin-bottom: .2em;
}

.Applications_subTitle__Y2E8q {
  color: #333;
  margin: .2em;
}

.Applications_memberApplication__3pGVr {
  margin: 1.5em 0;
}

.Applications_applicationTitle__3xxat {
  font-weight: 600;
}

.Applications_apply__3RX4c {
  display: block;
  padding: 5px 0;
}


  @media screen and (max-width: 1030px) {
    .Applications_applications__ddl29 {
      padding: .5em 0 1em 0;
      margin: 0;
      border-bottom: solid 1px lightgrey;
    }

    .Applications_memberContainer__1r3q8 {
      border-bottom: none;
    }

    .Applications_renewContainer__3B7Ui {
      padding: 1em 0 2em 0;
      border-bottom: solid 1px lightgrey;
    }

    .Applications_renewContainer__3B7Ui h3 {
      margin-top: 0;
    }
  }
.Affiliates_container__3PpuG {
    padding-top: 1em;
}

.Affiliates_title__1hrVn {
    text-align: center;
}

.Affiliates_container__3PpuG img {
    width: 75px;
    display: block;
    margin: 2em auto;
}

@media screen and (max-width: 1040px) {
    .Affiliates_container__3PpuG {
        border-top: solid 1px lightgrey;
    }
}
.ErrorMessage_errorMessage__1jbRE {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.EventsTable_table__3OYoZ {
    font-family: 'Poppins', sans-serif;
}

.EventsTable_head___q9bJ tr {
    font-weight: 600;
    text-align: left;
}

.EventsTable_table__3OYoZ th,
.EventsTable_table__3OYoZ td {
    padding: 10px 15px;
}

.EventsTable_table__3OYoZ tbody tr:nth-of-type(even) {
    background-color: var(--offwhite);
}

.EventsTable_title__3H5DR {
    text-align: left;
}

.EventsTable_register__dMsSe {
    padding: 7px 15px;
    background: var(--turqoise);
    color: white !important;
    border-radius: 3px;
    font-size: .9em;
}
.ToggleVersion_container__31Gv7 {
    grid-column: 3/4;
    grid-row: 1/2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
  
.ToggleVersion_loginWrapper__YkvRU {
    display: flex;
    align-items: center;
    color: var(--red);
    font-size: 14px;
}

.ToggleVersion_loginLink__5EBDG {
    font-weight: 500;   
}

.ToggleVersion_loginWrapper__YkvRU svg {
    fill: var(--red);
    width: 17px;
    height: 17px;
    margin-right: 8px;
    margin-bottom: 2px
}

.ToggleVersion_version__2rgQr {
    color: #595959;
    padding: 1em 2em 1em 1.5em;
    font-size: 14px;
}
  
.ToggleVersion_versionActive__y_cP1 {
    font-weight: 500;
    color: var(--light-green);
}

.ToggleVersion_pipe__1Idmt {
    color: lightgrey;
}
.MembersNav_nav__3Jm9P {
    grid-row: 2/4;
    grid-column: 2/4;
    align-self: flex-end;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 62px;
    padding: 0 6% 0 0;
    color: #333;
    font-size: 15px;
    font-weight: 500;
}

.MembersNav_navLink__2ZZMK {
    display: flex;
    align-items: center;
}

.MembersNav_submenuContainer__3HD48 {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.MembersNav_submenu__2lLkK {
    position: absolute;
    overflow: hidden;    
    display: flex;
    flex-direction: column;
    bottom: 0;
    top: 62.34px;
    left: 0;
    font-weight: 400;
    white-space: nowrap;
    background-color: white;
    border-top: solid 2px var(--gold);
    line-height: 1.5em;
    visibility: hidden;
    border-bottom: solid 1px lightgrey;
    border-right: solid 1px lightgrey;
    border-left: solid 1px lightgrey;
}
  
.MembersNav_submenuLink__8mGzM {
    padding: 10px 10px 10px 5px;
    border-bottom: solid 1px lightgrey;
}

.MembersNav_submenuLink__8mGzM:last-child {
    border-bottom: none;
}
  
.MembersNav_submenuLink__8mGzM:hover {
    background-color: var(--offwhite);
}
  
.MembersNav_inactive__1x5Uy {
    height: 0;
}

.MembersNav_membershipActive__3YSCO {
    height: 216.5px;
    visibility: visible;
    transition: height .1s;
}

.MembersNav_educationActive__38GXM {
    height: 128px;
    visibility: visible;
    transition: height .1s;
}

.MembersNav_aboutActive__2ngp1 {
    height: 85px;
    visibility: visible;
    transition: height .1s;
}
  
.MembersNav_classifiedsActive__BlOKo {
    height: 128px;
    visibility: visible;
    transition: height .1s;
}

/* Expand More  */
.MembersNav_nav__3Jm9P svg {
    width: 17px;
    height: 17px;
}
.GuestsNav_nav__-pLSr {
    grid-row: 2/4;
    grid-column: 2/4;
    align-self: flex-end;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 62px;
    padding: 0 6% 0 0;
    color: #333;
    font-size: 15px;
    font-weight: 500;
}

.GuestsNav_navLink__3WHzP {
    display: flex;
    align-items: center;
}


/* NAV SUBMENUS */
.GuestsNav_submenuContainer__31A8l {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.GuestsNav_submenu__3FSw1 {
    position: absolute;
    overflow: hidden;    
    display: flex;
    flex-direction: column;
    bottom: 0;
    top: 62.34px;
    left: 0;
    font-weight: 400;
    white-space: nowrap;
    background-color: white;
    border-top: solid 2px var(--gold);
    line-height: 1.5em;
    visibility: hidden;
    border-bottom: solid 1px lightgrey;
    border-right: solid 1px lightgrey;
    border-left: solid 1px lightgrey;
}

.GuestsNav_submenuLink__33CNX {
    padding: 10px 10px 10px 5px;
    border-bottom: solid 1px lightgrey;
}

.GuestsNav_submenuLink__33CNX:last-child {
    border-bottom: none;
}

.GuestsNav_submenuLink__33CNX:hover {
    background-color: var(--offwhite);
}

.GuestsNav_inactive__3lGac {
    height: 0;
}

.GuestsNav_membershipActive__WAOsf {
    height: 128px;
    visibility: visible;
    transition: height .1s;
}

.GuestsNav_educationActive__3ibyO {
    height: 128px;
    visibility: visible;
    transition: height .1s;
}

.GuestsNav_classifiedsActive__F4efI {
    height: 128px;
    visibility: visible;
    transition: height .1s;
}

.GuestsNav_aboutActive__2awzq {
    height: 85px;
    visibility: visible;
    transition: height .1s;
}

/* Expand More */
.GuestsNav_nav__-pLSr svg {
    width: 17px;
    height: 17px;
}
.Mobile_header__xsI2e {
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: solid 1px black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    z-index: 500;
}

.Mobile_hamburger__ByWaQ {
    height: 25px;
    width: 25px;
    margin-left: 1em;
}

.Mobile_hamburger__ByWaQ:hover {
    cursor: pointer;
}

.Mobile_title__1HJeg {
    font-family: "Poppins", sans-serif;
    padding-right: 1em;
    color: #595959;
}

.Mobile_title__1HJeg:hover {
    cursor: pointer;
}

.Mobile_navList__10n93 {
    justify-self: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 2em;
    font-size: .9em;
    font-weight: 600;
}

.Mobile_navList__10n93 svg , .Mobile_subList__1D5Y_ svg{
    height: 16px;
    width: 16px;
    fill: white;
    padding: 0 5px;
}


.Mobile_container__VDfeu {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    z-index: 500;
    background-color: var(--turqoise);
    font-family: "Poppins", sans-serif;
    color: white;
    border-right: solid 1px white;
    overflow: hidden;
    -webkit-animation: Mobile_slide-in-right__ROAVP .4s;
            animation: Mobile_slide-in-right__ROAVP .4s;
}

.Mobile_subList__1D5Y_ {
    position: absolute;
    top: 0;
    left: 0;
    justify-self: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2em;
    font-weight: 600;
    font-size: .9em;
    z-index: 600;
    background-color: var(--turqoise);
    -webkit-animation: Mobile_slide-in__2yPG7;
            animation: Mobile_slide-in__2yPG7;
    -webkit-animation-duration: .4s;
            animation-duration: .4s;
}

.Mobile_container__VDfeu .Mobile_back__1wnBm {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Mobile_container__VDfeu li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    width: 200px;
    border-bottom: solid 1px white;
}

.Mobile_container__VDfeu li:hover {
    cursor: pointer;
}

.Mobile_switch__26XvW {
    font-weight: 400;
}

.Mobile_arrow__34W43 {
    font-size: 1em;
    padding: 0 5px;
}



.Mobile_close__3o3PL {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 700;
}

.Mobile_close__3o3PL svg {
    height: 25px;
    height: 25px;
    padding: 20px 20px 0 0;
    fill: white;
}

.Mobile_close__3o3PL:hover {
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .Mobile_title__1HJeg {
        font-size: 18px;
        padding-right: 10px;
    }
}

@-webkit-keyframes Mobile_slide-in__2yPG7 {
    0% {
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
        opacity: 0
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@keyframes Mobile_slide-in__2yPG7 {
    0% {
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
        opacity: 0
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes Mobile_slide-in-right__ROAVP {
    0% {
        -webkit-transform: translateX(-50vw);
                transform: translateX(-50vw);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes Mobile_slide-in-right__ROAVP {
    0% {
        -webkit-transform: translateX(-50vw);
                transform: translateX(-50vw);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
.Social_social__2W2o8 {
    padding-right: 10px;
    display: flex;
}

.Social_social__2W2o8 svg {
    width: 14px;
    height: 14px;
    margin: 0 15px 0 0;
    fill: lightgrey;
    vertical-align: middle;
 }

.Social_social__2W2o8 svg:hover {
    fill: grey;
}
.Header_header__1VCKf {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    align-items: center;
    border-bottom: solid 1px lightgrey;
    background-color: white;
    box-sizing: border-box;
    z-index: 300;
    font-family: 'Poppins', sans-serif;
}

.Header_logo__3_SJs {
    justify-self: center;
    grid-column: 1/2;
    grid-row: 1/4;
    padding: 10px;
    height: 130px;
    transition: height .3s;
}

.Header_logo__3_SJs:hover {
    cursor: pointer;
}

.Header_logoScrolled__1XZkT {
    height: 95px;
    transition: height .3s;
}

@media screen and (min-width: 1500px) {
    .Header_header__1VCKf {
        padding: 0 3%;
    }
}

@media screen and (min-width: 1650px) {
    .Header_header__1VCKf {
        padding: 0 5%;
    }
}

@media screen and (min-width: 1700px) {
    .Header_header__1VCKf {
        padding: 0 8%;
    }
}

@media screen and (min-width: 1850px) {
    .Header_header__1VCKf {
        padding: 0 10%;
    }
}
.make-post-sidebar h3 {
    color: var(--light-green);
}

.make-post-btn {
    background: transparent;
    border: solid 1px lightgrey;
    padding: .5em .6em;
}

.make-post-btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 1070px) {
    .make-post-sidebar {
        padding: 0 1em 0 0;
        margin: 0;
    }
}
.Posts_box__3twi_ {
    padding: 1em 0;
    border-bottom: solid 1px lightgrey;
    font-family: "Poppins", sans-serif;
}

.Posts_box__3twi_:first-child {
    padding-top: 0;
}

.Posts_box__3twi_:last-child {
    border-bottom: none;
}
.season-ticket-sidebar {
    padding-right: 1em;
}

.season-ticket-sidebar h3 {
    color: var(--light-green);
}

.season-ticket-sidebar .material-icons {
    color: grey;
    font-size: 16px;
    padding: 0 0 0 3px;
}

@media screen and (max-width: 1030px) {
    .season-ticket-sidebar {
        border-bottom: solid 1px lightgrey;
    }
}
.join-renew-body {
  display: flex;
  min-height: 100vh;
  padding: 2em 8%;
}
.join-renew-body {
  display: flex;
  min-height: 100vh;
  padding: 2em 8%;
}
.advertising-title-container {
    background: url(/static/media/advertising.c1fa3832.jpg);
    background-size: cover;
  }
.dr-list-container {
    border-top: solid 1px lightgrey;
    min-height: 100vh;
    font-family: "Poppins", sans-serif;
}

.dr-list-container:last-child {
    border-bottom: none;
}

.spinner-border-top {
    border-top: solid 1px lightgrey;
}

.speciality {
    color: #595959;
}
.search-dentists {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    top: 170px;
    padding: .6em 1em 0 0;
    font-weight: 500;
    background-color: white;
}

.speciality-select, .city-select, .name-input {
    margin: 1em 0;
    font-size: 16px;
}

.search-title {
    margin-top: 0;
    padding-bottom: .5em;
    border-bottom: solid 1px lightgrey;
}

.name-form {
    display: flex;
    flex-direction: column;
}

.name-label {
    font-size: .8em;
}

.name-input {
    height: 30px;
    border: solid 1px lightgrey;
    font-family: "Poppins", sans-serif;
}

.search-reset-btn {
    margin-top: 1em;
    padding: .2em .4em;
    border: solid 1px lightgrey;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.search-reset-btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 1030px) {
    .search-dentists {
        width: 75%;
        margin: auto;
    }

    .name-input {
        text-align: center;
    }
}
.find-a-dentist-title-container {
    background: url(/static/media/find-banner.42203105.jpg);
    background-size: cover;
    background-position: 100% 20%;
}

.find-a-dentist-content {
    grid-gap: 2em;
    gap: 2em;
    padding: 1em 50px 2em 50px;
    min-height: 100vh;
}

.pagination {
    grid-column: 2/3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-list {
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

.pagination-list li {
    padding: .3em;
}

.pagination-list li:last-child {
    border: none;
}

.pagination-list li:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}

.pagination-list-page {
    background: white;
}

.pagination-list-page-active {
    color: var(--turqoise);
    background: white;
}

/* DR BOXES */
.dr-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 0 0 4em;
}

.dr-box {
    display: flex; 
    justify-content: space-around;
    align-items: center;
    padding: 2em 0;
    width: 100%;
    /* max-height: 180px; */
    background-color: white;
    margin: .5em 0;
    line-height: 1.5em;
    border-bottom: solid 1px lightgrey;
    
}

.dr-img-wrapper {
    width: 15%;
    display: flex;
    justify-content: center;
}

.name-speciality {
    width: 30%;
    padding: 0 10px;
}

.address {
    width: 35%;
    font-size: 1em;
    font-weight: 300;
}

.second-address {
    display: block;
    margin-top: 1em;
    font-weight: 300;
}

.phone-website {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1em;
    font-weight: 300;
}

.dr-img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    /* object-position: 100% 20%; */
    border-radius: 5px;
}

.phone-fax svg {
    height: 17px;
    width: 17px;
    padding-right: 5px;
    vertical-align: middle;
}

.website-wrapper {
    text-align: center;
}

@media screen and (max-width: 1245px) {
    .dr-box {
        width: 100%;
        margin: 0;
    }

    .name-speciality {
        padding: 0;
    }
}

@media screen and (max-width: 800px){
    .dr-box {
        flex-direction: column;
        text-align: center;
    }

    .dr-image-wrapper, .address, .phone-website {
        width: 100%;
    }

    .name-speciality {
        width: 100%;
    }
    
    .speciality {
        margin: 0;
    }

    .phone {
        margin: 0 0 1em 0;
    }
}
.directory-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 50vh;
}

.member-search {
    margin-bottom: 0;
    font-weight: 500;
}

.member-reset-btn {
    border: solid 1px lightgrey;
    display: inline-block;
    padding: 2px 5px;
    margin: 0;
}

.directory-pagination-top {
    border-bottom: solid 1px lightgrey;
}

.directory-pagination-top {
    border-bottom: solid 1px lightgrey;
}
.directory-pagination-bottom {
    border-top: solid 1px lightgrey;
}

.directory-box {
    width: 215px;
    margin: 1em 1em 2em 1em;
    padding: 1em 0;
}

.dir-img {
    height: 95px;
    max-width: 100px;
    border-radius: 3px;
    object-fit: cover;
}

.dir-name {
    margin: 0;
}

.dir-specialty {
    margin: 0;
}

.dir-text {
    font-size: 15px;
    line-height: 1.5em;
}

.dir-website {
    color: var(--light-green);
    display: block;
    margin-top: 10px;
}

.dir-website:hover {
    color: var(--light-green);
}

@media screen and (min-width: 1350px) {
    .directory-box {
        margin: 1em 2em 2em 2em;
    }
}

@media screen and (max-width: 1030px) {
    .directory-pagination-top {
        padding-top: 1em;
    }
}

@media screen and (max-width: 530px) {
    .directory-container {
        justify-content: center;
        align-items: center;
    }

    .directory-box {
        width: 100%;
        padding-bottom: 2em;
        border-bottom: solid 1px lightgrey;
        text-align: center;
    }

    .directory-box:last-child {
        border-bottom: none;
    }
}
#goldstein {
    object-position: top;
}

.box-text h3 {
    color: var(--light-green);
    font-weight: 600;
    margin: 0;
}

.box-text h4 {
    font-weight: 500;
    margin: 0;
}

.board {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.staff {
    display: flex;
    justify-content: center;
}

.board {
    padding-bottom: 2em;
    border-bottom: solid 3px var(--gold);
}

.board-title, .staff-title {
    text-align: center;
}

.staff-title {
    padding-top: 1em;
}

.board-staff-box {
    display: grid;
    grid-template-rows: 250px auto;
    width: 200px;
    margin: 1em 4em;
}

.board-staff-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.box-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.affiliates-small-wrapper {
    display: none;
}

@media screen and (max-width: 1040px) { 
    .board-staff-content {
      display: block;
      padding: 1em;
    } 

    .affiliates-small-wrapper {
        display: block;
    }

    .affiliates-wide-wrapper {
        display: none;
    }
}
.resources-title-container {
  background: url(/static/media/resources-banner.9b2a6aef.jpg);
  background-size: cover;
  background-position: center;
}

.resources-regulatory {
  margin-top: 3em;
  padding-top: 3em;
  border-top: solid 3px var(--gold);
}

.general-box li:hover {
  color: var(--light-green);
}

.regulatory-box li:hover {
  color: var(--light-green);
}
.continuing-education-requirements-title-container {
    background: url(/static/media/ce-banner.8dd04fe1.jpg);
    background-size: cover;
    background-position: 100% 20%;
}

.dentists {
  padding-bottom: 3em;
  border-bottom: solid 5px var(--gold);
}

.assistants {
  margin-top: 3em;
}

.float-img {
  height: 200px;
  width: 200px;
  float: right;
  object-fit: cover;
  margin:  0 0 1em 1em;
}

@media screen and (max-width: 1000px) {
  .float-img {
    float: unset;
    margin: 0;
  }
}
.cpr-certification-requirements-title-container {
    background: url(/static/media/cpr-banner.f13e9f83.jpg);
    background-size: cover;
    background-position: 100% 25%;
}
.EventsList_box__mJE9m {
    border-bottom: solid 1px lightgrey;
    padding: 1em 5px;
    font-family: 'Poppins', sans-serif;
}

.EventsList_title__3vIh5 {
    font-weight: 600;
    color: var(--light-green);
    margin: 0;
    padding: .7em 0;
}

.EventsList_date__dM4n0 {
    margin: .5em 0;
}
.Calendar_container__RqH5a {
    width: 80%;
    margin: .8em 2em;
    box-sizing: border-box;
}
.news-item a {
    color: var(--dark-blue);
}

.news-item-container > .title {
    font-size: 2em;
}

.news-item-container > .img {
    max-height: 300px;
    max-width: 300px;
    object-fit: cover;
    border: solid 1px lightgrey;
}

.news-item-container > .date {
    font-size: .9em;
}

.news-item-container > .excerpt {
    border-bottom: solid 1px lightgrey;
    font-weight: 600;
}


.news-title-container {
    background: url(/static/media/news-banner.34c001e9.jpg);
    background-size: cover;
}

.news-feed-container:hover {
    cursor: pointer;
}

.news-feed-container > .active {
    background-color: var(--offwhite);
}

.news-feed-item {
    padding: 1.5em 5px;
    border-bottom: solid 1px var(--turqoise);
}

.news-feed-title {
    color: var(--light-green);
    font-weight: 600;
    margin-top: 0;
}

.news-feed-date {
    color: grey;
    font-size: .8em;
    margin-bottom: 0;
}

@media screen and (max-width: 1030px) {
    .news-subpage {
        display: flex;
        flex-direction: column-reverse;
    }
}
.employment-opportunities-title-container {
    background: url(/static/media/employment-banner.27ca0160.jpg);
    background-size: cover;
    background-position: 100% 35%;
}
.practice-opportunities-title-container {
    background: url(/static/media/practice-banner.d5a0f9a7.jpg);
    background-size: cover;
}
.other-title-container {
    background: url(/static/media/practice-banner.d5a0f9a7.jpg);
    background-size: cover;
}
#history-logo {
    width: 150px;
    padding: 2em;
    float: right;
}

@media screen and (max-width: 1040px) {
    #history-logo {
        width: 150px;
        padding: 2em;
        float: unset;
        display: block;
        margin: auto;
    }
}
.join-renew-title-container {
  background: url(/static/media/laptop-phone-table.66780c02.jpg);
  background-size: cover;
}

.join-renew-body {
  display: flex;
  min-height: 100vh;
  padding: 2em 8%;
}
.member-benefits-title-container {
    background: url(/static/media/study.05e08068.jpg);
      background-size: cover;
  }

.member-benefits-content ul {
    display: flex;
    flex-wrap: wrap;
}

.member-benefits-content li {
    width: 45%;
}
.foundation-title-container {
    background: url(/static/media/books-pattern.12949999.jpg);
    background-size: cover;
  }
.legal-disclaimer {
    margin-top: 160px;
    min-height: 100vh;
    line-height: 2em;
    padding: 1em 5%;
}

@media screen and (max-width: 1070px) {
    .legal-disclaimer {
        margin-top: 109px;
    }
}
.privacy-policy {
    margin-top: 160px;
    min-height: 100vh;
    line-height: 2em;
    padding: 1em 5%;
}

@media screen and (max-width: 1070px) {
    .privacy-policy {
        margin-top: 109px;
    }
}
.LandingPage_landing__-hLD_ {
    height: 100vh;
    font-family: "Poppins", sans-serif;
}

.LandingPage_guests__1O1Jn {
    height: 50%;
    background: linear-gradient(#27a2adc9, #27a2adc9), url(/static/media/family.a1a3ef70.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LandingPage_members__1UQTh {
    height: 50%;
    background: linear-gradient(#F1B435c9, #f1b535c9), url(/static/media/dentist-working.c2a099e3.jpg);
    background-position: center;
    background-size: cover;
    background-color: var(--gold);
    display: flex;
    justify-content: center;
    align-items: center;
}

.LandingPage_guestsTitle__14y81 {
    font-size: 3.2em;
    color: white;
    font-weight: 500;
    margin-top: 2em;
}

.LandingPage_membersTitle__2toK5 {
    font-size: 3.2em;
    color: white;
    font-weight: 500;
    margin-bottom: 2em;
}

.LandingPage_logoWrapper__3H_WZ {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.LandingPage_logo__3s7rB {
    width: 180px;
    background-color: white;
    padding: 15px 20px;
    border-radius: 42%;
    overflow: visible;
}

@media screen and (min-width: 1500px) {
    .LandingPage_logo__3s7rB {
        width: 220px;
    }
}
.banner-img {
  position: absolute;
  top: -43px;
  left: 0;
  width: 100%;
  padding-top: 43px;
  height: 550px;
  z-index: -1;
  object-fit: cover;
  object-position: 100% 30%;
}

.banner-active {
  opacity: 1;
  -webkit-animation: slow-zoom 10s;
          animation: slow-zoom 10s;
  transition: 2s;
}

.banner-inactive {
  opacity: 0;
  transition: 2s;
}

.hero-banner {
  position: relative;
  height: 550px;
  max-width: 1500px;
  margin: 130px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(to right, rgba(20, 20, 20, 0.45), transparent);
}

.progress-bar {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.39);
  -webkit-animation: draw-line 10000ms linear;
          animation: draw-line 10000ms linear;
}

.banner-title-container {
  align-self: flex-start;
  -webkit-animation: fade-in 1s;
          animation: fade-in 1s;
  padding: 4em 0 0 3em;
  color: white;
  z-index: 100;
  width: 50%;
  line-height: 5em;
  font-family: 'Poppins', sans-serif;
}

.mcds-title {
  font-size: 3.2em !important;
  line-height: 1.7em;
}

.banner-content {
  -webkit-animation: fade-in 2s;
          animation: fade-in 2s;
}

.banner-title {
  font-size: 2.8em;
  font-weight: 500;
  margin-top: 0;
}

.hero-btn {
  padding: .7em 1.5em;
  margin: 0 4em 0 0;
  font-size: 1em;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  color: white;
  background: var(--gold);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.hero-btn:hover {
  cursor: pointer;
}

.banner-buttons {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  color: white;
}

.banner-buttons:hover {
  cursor: pointer;
}

/* Animations */
@-webkit-keyframes slow-zoom {
  0% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes slow-zoom {
  0% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes draw-line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes draw-line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes open-banner {
  0% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes open-banner {
  0% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Media Queries */
@media screen and (max-width: 1070px) {
  .hero-banner {
    margin-top: 75.81px;
  }
}

@media screen and (max-width: 850px) {
  .hero-banner {
    background: rgba(0,0,0, .55);
  }

  .banner-title-container {
    width: 100%;
    text-align: center;
    padding:  1em 10px 10px 10px;
    box-sizing: border-box;
  }

  .hero-btn {
    margin: 0 10px;
  }
}

@media screen and (max-width: 600px) {
  .banner-title {
    font-size: 3.3em;
  }

  .mcds-title {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 500px) {
  .hero-banner{
    margin-top: 57.88px;
  }
}

@media screen and (max-width: 450px) {
  .banner-title {
    font-size: 2.2em;
    padding: 10px;
  }

  .mcds-title {
    font-size: 3.1em !important;
    line-height: 1.5em;
    padding: 10px;
  }

  .hero-btn {
    font-size: .8em;
  }

  .mcds-welcome {
    font-size: 3em;
  }
}

.banner-boxes {
    padding: 3em 1em;
    grid-column: 1/3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #F6F4F3;
    overflow: hidden;
}

.banner-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    text-align: center;
    width: 220px;
    height: 300px;
    margin: 1em;
    padding: 1em 1em 1.5em 1em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px grey;

}

.banner-box-icon {
    width: 75px;
}

.box-title {
    color: #595959;
    min-height: 50px;
}

.box-btn {
    padding: .5em 1em;
    font-size: .9em;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: var(--gold);
}

.box-btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .banner-boxes {
        padding: 1em;
    }

    .banner-box {
        width: 250px;
    }
}
.events_container__1UbS2 {
    grid-column: 1/3;
    padding: 2em 7% 3em 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.events_heading__2J3dV {
    text-align: center;
    margin-bottom: 2em;
    font-weight: 600;
    font-size: 1.8em;
    color: #595959;
}

.events_eventsContainer__x05oH {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.events_event__2mQne:nth-of-type(even) {
    background-color: #F9F9F9;
}

.events_event__2mQne {
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.events_event__2mQne:last-child {
    border-bottom: none;
}

.events_summary__1lK7e {
    color: #333;
    font-weight: 500;
}

.events_date__1TC8y {
    font-size: 1.1em;
    color: #595959;
    justify-self: flex-end;
}


.events_button__18_aH {
    height: 35px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--turqoise);
    color: white;
    border: none;
    font-weight: 600;
    font-size: .9em;
    text-align: center;
    border-radius: 5px;
    justify-self: flex-end;
}

.events_linkButton__3_nVg {
    margin-top: 2em;
    height: 40px;
    width: 150px;
    background-color: white;
    border: solid 1px grey;
    font-size: .9em;
    color: black;
}

button:hover {
    cursor: pointer;
}

@media screen and (max-width: 1070px) {
    .events_eventsContainer__x05oH {
        width: 100%;
    }

    .events_event__2mQne {
        grid-template-columns: 1fr .5fr .5fr;
    }

    .events_summary__1lK7e {
        padding-right: 10px;
    }
    .events_date__1TC8y {
        font-size: 1em;
    }

    .events_button__18_aH {
        width: auto;
        height: auto;
        padding: 7px 5px;
        font-size: .8em;
    }
}
.announcements {
    grid-column: 1/3;
    padding: 1em 100px;
    background: #F6F4F3;
    overflow: hidden;
    position: relative;
}

.announcements-title {
    position: relative;
    font-weight: 600;
    font-size: 1.8em;
    color: #595959;
    text-align: center;
}

.announcements-content {
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.announcement-box {
    width: 290px;
    margin: .5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.announcement-title {
    margin: .7em 0;
    font-weight: 600;
    color: #333;
}

.announcement-content {
    line-height: 1.7em;
}

.announcement-content p {
    margin-top: 0;
}

.announcement-image {
    height: 185px;
    object-fit: cover;
}

.announcement-button {
    align-self: center;
    padding: .5em;
    font-size: .9em;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    background: var(--gold);
    color: white;
}

.announcement-button:hover {
    cursor: pointer;
}

.news-link-button {
    display: block;
    margin: 0 auto 2em auto;
    margin-top: 2em;
    height: 40px;
    width: 150px;
    background: transparent;
    border: solid 1px grey;
    color: black;
    font-size: .9em;
}

@media screen and (max-width: 600px) {
    .announcements {
        padding: 1em;
    }
}
.WhoWeAre_about__1fYaO h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 2em;
  border-left: solid 3px var(--gold);
  padding-left: 1em;
}

.WhoWeAre_about__1fYaO p {
  font-size: 1.2em;
  font-family: 'Poppins', sans-serif;
}

.WhoWeAre_about__1fYaO {
  grid-column: 1/3;
  padding: 2em 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient( #595959e0, #595959e0), url(/static/media/triangles-dots.026e1e78.jpg);
  background-size: cover;
  background-position: center;
  color: white;
}

.WhoWeAre_logo__PM_6v {
  width: 200px;
  margin: 1em auto 2em auto;
}

.WhoWeAre_articlesWrapper__12U9C {
  display: flex;
  justify-content: space-around;
}

.WhoWeAre_mission__AFt-N {
  width: 30%;
}

.WhoWeAre_who__3U7Gi {
  width: 50%;
}

@media screen and (max-width: 1095px) {
  .WhoWeAre_about__1fYaO {
    padding: 2em 5%;
  }
}

@media screen and (max-width: 700px) {

  .WhoWeAre_articlesWrapper__12U9C {
    flex-direction: column;
  }

  .WhoWeAre_mission__AFt-N, .WhoWeAre_who__3U7Gi {
    width: 100%;
  }
}
.Sponsors_sponsors__3c0Gs {
    padding: 1em 100px;
}

.Sponsors_title__1Kb4G {
    font-weight: 600;
    font-size: 1.8em;
    color: #595959;
    text-align: center;
    margin-bottom: 2em;
}

.Sponsors_container__35pRy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.Sponsors_sponsors__3c0Gs li {
    margin: 0 1em;
}

.Sponsors_sponsors__3c0Gs img {
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
}

.Sponsors_sponsors__3c0Gs figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Sponsors_sponsors__3c0Gs figcaption {
    margin: 1em 0;
}
input[type=submit] {
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
}

.contact-form-container {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
}

#contact-form {
    display: flex;
    flex-direction: column;
}

#name {
    height: 25px;
    margin: .3em 0;
    font-size: 15px;
    border: solid 1px lightgrey;
    padding: 0 0 0 5px;
}

#email {
    height: 25px;
    margin: .3em 0;
    font-size: 15px;
    border: solid 1px lightgrey;
    padding: 0 0 0 5px;
}

#message {
    height: 60px;
    margin: .3em 0;
    font-size: 15px;
    border: solid 1px lightgrey;
    font-family: 'Poppins', sans-serif;
    padding: 0 0 0 5px;
}

#send {
    padding: 4px 7px;
    font-size: 16px;
    font-weight: 600;
    align-self: flex-end;
    background-color: var(--gold);
    border-radius: 5px;
    margin-top: 5px;
    color: white;
    border: none;
}

#send:hover {
    color: black;
}

.home-contact {
    grid-column: 1/3;
    padding: 3em 10%;
    background-color: var(--offwhite);
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}

.home-contact-content {
    display: flex;
    justify-content: space-between;
}

.home-contact-affiliates img {
    height: 50px;
    margin: 1em 1em 1em 0;
}

.contact-info {
    line-height: 1.5em;
}

@media screen and (max-width: 850px) {
    .home-contact-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .contact-form-container {
        width: 50%;
    }
}

@media screen and (max-width: 575px) {
    .contact-form-container {
        width: 75%;
    }
}



:root {
  --text-grey:rgb(100, 100, 100);
  --gold: #F1B435;
  --green: rgb(146,167,136);
  --dark-blue: #176992;
  --light-blue: #62bbe7;
  --grey-green: #88A2A7;
  --light-green: #337e86;
  --turqoise: #27a2ad;
  --red: #FD8369;
  --offwhite: rgb(248,247,245);
}

main {
  font-family: 'Poppins', sans-serif;
  color: #333;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

.material-icons {
  vertical-align: middle;
}

.react-spinner {
  display: block;
  margin: 8em auto;
}

/* Text styles for when there is no content to display: */
.nothing {
  color: grey;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 1800px) {
  body {
    margin: auto;
    background-color: white;
  }
}
