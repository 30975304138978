@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
  --text-grey:rgb(100, 100, 100);
  --gold: #F1B435;
  --green: rgb(146,167,136);
  --dark-blue: #176992;
  --light-blue: #62bbe7;
  --grey-green: #88A2A7;
  --light-green: #337e86;
  --turqoise: #27a2ad;
  --red: #FD8369;
  --offwhite: rgb(248,247,245);
}

main {
  font-family: 'Poppins', sans-serif;
  color: #333;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

.material-icons {
  vertical-align: middle;
}

.react-spinner {
  display: block;
  margin: 8em auto;
}

/* Text styles for when there is no content to display: */
.nothing {
  color: grey;
}