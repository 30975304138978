.legal-disclaimer {
    margin-top: 160px;
    min-height: 100vh;
    line-height: 2em;
    padding: 1em 5%;
}

@media screen and (max-width: 1070px) {
    .legal-disclaimer {
        margin-top: 109px;
    }
}