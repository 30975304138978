.container {
    grid-column: 1/3;
    padding: 2em 7% 3em 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    text-align: center;
    margin-bottom: 2em;
    font-weight: 600;
    font-size: 1.8em;
    color: #595959;
}

.eventsContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.event:nth-of-type(even) {
    background-color: #F9F9F9;
}

.event {
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.event:last-child {
    border-bottom: none;
}

.summary {
    color: #333;
    font-weight: 500;
}

.date {
    font-size: 1.1em;
    color: #595959;
    justify-self: flex-end;
}


.button {
    height: 35px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--turqoise);
    color: white;
    border: none;
    font-weight: 600;
    font-size: .9em;
    text-align: center;
    border-radius: 5px;
    justify-self: flex-end;
}

.linkButton {
    margin-top: 2em;
    height: 40px;
    width: 150px;
    background-color: white;
    border: solid 1px grey;
    font-size: .9em;
    color: black;
}

button:hover {
    cursor: pointer;
}

@media screen and (max-width: 1070px) {
    .eventsContainer {
        width: 100%;
    }

    .event {
        grid-template-columns: 1fr .5fr .5fr;
    }

    .summary {
        padding-right: 10px;
    }
    .date {
        font-size: 1em;
    }

    .button {
        width: auto;
        height: auto;
        padding: 7px 5px;
        font-size: .8em;
    }
}