#goldstein {
    object-position: top;
}

.box-text h3 {
    color: var(--light-green);
    font-weight: 600;
    margin: 0;
}

.box-text h4 {
    font-weight: 500;
    margin: 0;
}

.board {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.staff {
    display: flex;
    justify-content: center;
}

.board {
    padding-bottom: 2em;
    border-bottom: solid 3px var(--gold);
}

.board-title, .staff-title {
    text-align: center;
}

.staff-title {
    padding-top: 1em;
}

.board-staff-box {
    display: grid;
    grid-template-rows: 250px auto;
    width: 200px;
    margin: 1em 4em;
}

.board-staff-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.box-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.affiliates-small-wrapper {
    display: none;
}

@media screen and (max-width: 1040px) { 
    .board-staff-content {
      display: block;
      padding: 1em;
    } 

    .affiliates-small-wrapper {
        display: block;
    }

    .affiliates-wide-wrapper {
        display: none;
    }
}