.nav {
    grid-row: 2/4;
    grid-column: 2/4;
    align-self: flex-end;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 62px;
    padding: 0 6% 0 0;
    color: #333;
    font-size: 15px;
    font-weight: 500;
}

.navLink {
    display: flex;
    align-items: center;
}

.submenuContainer {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.submenu {
    position: absolute;
    overflow: hidden;    
    display: flex;
    flex-direction: column;
    bottom: 0;
    top: 62.34px;
    left: 0;
    font-weight: 400;
    white-space: nowrap;
    background-color: white;
    border-top: solid 2px var(--gold);
    line-height: 1.5em;
    visibility: hidden;
    border-bottom: solid 1px lightgrey;
    border-right: solid 1px lightgrey;
    border-left: solid 1px lightgrey;
}
  
.submenuLink {
    padding: 10px 10px 10px 5px;
    border-bottom: solid 1px lightgrey;
}

.submenuLink:last-child {
    border-bottom: none;
}
  
.submenuLink:hover {
    background-color: var(--offwhite);
}
  
.inactive {
    height: 0;
}

.membershipActive {
    height: 216.5px;
    visibility: visible;
    transition: height .1s;
}

.educationActive {
    height: 128px;
    visibility: visible;
    transition: height .1s;
}

.aboutActive {
    height: 85px;
    visibility: visible;
    transition: height .1s;
}
  
.classifiedsActive {
    height: 128px;
    visibility: visible;
    transition: height .1s;
}

/* Expand More  */
.nav svg {
    width: 17px;
    height: 17px;
}