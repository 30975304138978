.sidebar {
    width: 20%;
    border-right: solid 1px lightgrey;
    padding-right: 2em;
    box-sizing: border-box;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
  }
  
  .sidebar-header {
    margin: 0;
    padding: 1.2em 0 5px 0;
    font-weight: 600;
    font-size: 1.3em;
    color: #333;
    border-bottom: solid 1px #595959;
  }

  .sidenav {
    border-bottom: solid 1px #595959;
  }
  
  .sidenav > .active {
    color: #333;
    font-weight: 600;
  }
  
  .sidenav > li {
    margin: 1em 0;
    font-size: 14px;
    position: relative;
  }

  .sidenav > li:hover {
    cursor: pointer;
  }
  
  .sidebar-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6em 0;
    font-size: 15px;
  }
  
  .sidebar-box svg {
    width: 65px;
    height: 65px;
    padding-bottom: 10px;
  }

  .mobile-subpage-select {
    display: none;
    text-align: center;
  }

  @media screen and (max-width: 1030px) {
    .sidebar {
      width: 100%;
      text-align: center;
      border-right: none;
      padding: 0;
      font-size: 16px;
    }

    .sidebar-header {
      display: none;
    }

    .sidenav-container {
      display: none;
      border-bottom: none;
    }

    .mobile-subpage-select {
      display: block;
    }
  }