.section {
    width: 80%;
    padding: 0 1em 0 2em;
    line-height: 2em;
    box-sizing: border-box;
    overflow: hidden;
}

.section h1 {
    color: #595959;
}

.section a {
    font-weight: 500;
    color: var(--light-green);
}

.section button {
    border: solid 1px lightgrey;
}

.section figure {
    margin: 0;
}

.section table {
    width: 100%;
    margin: 2em 0;
    text-align: center;
    border-collapse: collapse;
}

.section table thead th {
    padding: .3em 1em;
}

.section table thead th:first-of-type {
    border-radius: 5px 0 0 0;
}

.section table thead th:last-of-type {
    border-radius: 0 5px 0 0;
}

.section table th {
    background-color: var(--light-green);
    color: white;
    font-weight: 600;
}

.section table tr:nth-child(even) {
    background-color: var(--offwhite);
}

.section table td {
    padding:  1.5em 1em;
    text-align: left;
    color: black;
}

@media screen and (max-width: 1030px) {
    .section {
        width: 100%;
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .section {
        width: 100%;
        padding: 0;
    }

    .section table th {
        display: none;
    }

    .section table tr {
        display: flex;
        flex-direction: column;
        margin: 15px;
    }

    .section table tr td:first-child {
        background-color: #595959;
        color: white;
    }
}