.banner-boxes {
    padding: 3em 1em;
    grid-column: 1/3;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #F6F4F3;
    overflow: hidden;
}

.banner-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    text-align: center;
    width: 220px;
    height: 300px;
    margin: 1em;
    padding: 1em 1em 1.5em 1em;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px grey;

}

.banner-box-icon {
    width: 75px;
}

.box-title {
    color: #595959;
    min-height: 50px;
}

.box-btn {
    padding: .5em 1em;
    font-size: .9em;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: var(--gold);
}

.box-btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .banner-boxes {
        padding: 1em;
    }

    .banner-box {
        width: 250px;
    }
}