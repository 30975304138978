.footer {
    display: flex;
    justify-content: space-between;
    height: min-content;
    padding: .3em 2.5em;
    font-size: 13px;
    font-weight: 400;
    color: white;
    background-color: #595959;
    font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 620px) {
    .footer {
        flex-direction: column;
        text-align: center;
        padding: .3em;
    }

    .legal-privacy {
        padding-bottom: .2em;
    }
}