.news-item a {
    color: var(--dark-blue);
}

.news-item-container > .title {
    font-size: 2em;
}

.news-item-container > .img {
    max-height: 300px;
    max-width: 300px;
    object-fit: cover;
    border: solid 1px lightgrey;
}

.news-item-container > .date {
    font-size: .9em;
}

.news-item-container > .excerpt {
    border-bottom: solid 1px lightgrey;
    font-weight: 600;
}

