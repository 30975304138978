.about h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 2em;
  border-left: solid 3px var(--gold);
  padding-left: 1em;
}

.about p {
  font-size: 1.2em;
  font-family: 'Poppins', sans-serif;
}

.about {
  grid-column: 1/3;
  padding: 2em 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient( #595959e0, #595959e0), url("../../../assets/backgrounds/triangles-dots.jpg");
  background-size: cover;
  background-position: center;
  color: white;
}

.logo {
  width: 200px;
  margin: 1em auto 2em auto;
}

.articlesWrapper {
  display: flex;
  justify-content: space-around;
}

.mission {
  width: 30%;
}

.who {
  width: 50%;
}

@media screen and (max-width: 1095px) {
  .about {
    padding: 2em 5%;
  }
}

@media screen and (max-width: 700px) {

  .articlesWrapper {
    flex-direction: column;
  }

  .mission, .who {
    width: 100%;
  }
}