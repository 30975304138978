.dr-list-container {
    border-top: solid 1px lightgrey;
    min-height: 100vh;
    font-family: "Poppins", sans-serif;
}

.dr-list-container:last-child {
    border-bottom: none;
}

.spinner-border-top {
    border-top: solid 1px lightgrey;
}

.speciality {
    color: #595959;
}