.social {
    padding-right: 10px;
    display: flex;
}

.social svg {
    width: 14px;
    height: 14px;
    margin: 0 15px 0 0;
    fill: lightgrey;
    vertical-align: middle;
 }

.social svg:hover {
    fill: grey;
}