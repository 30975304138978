.header {
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: solid 1px black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    z-index: 500;
}

.hamburger {
    height: 25px;
    width: 25px;
    margin-left: 1em;
}

.hamburger:hover {
    cursor: pointer;
}

.title {
    font-family: "Poppins", sans-serif;
    padding-right: 1em;
    color: #595959;
}

.title:hover {
    cursor: pointer;
}

.navList {
    justify-self: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 2em;
    font-size: .9em;
    font-weight: 600;
}

.navList svg , .subList svg{
    height: 16px;
    width: 16px;
    fill: white;
    padding: 0 5px;
}


.container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    z-index: 500;
    background-color: var(--turqoise);
    font-family: "Poppins", sans-serif;
    color: white;
    border-right: solid 1px white;
    overflow: hidden;
    animation: slide-in-right .4s;
}

.subList {
    position: absolute;
    top: 0;
    left: 0;
    justify-self: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2em;
    font-weight: 600;
    font-size: .9em;
    z-index: 600;
    background-color: var(--turqoise);
    animation: slide-in;
    animation-duration: .4s;
}

.container .back {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.container li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    width: 200px;
    border-bottom: solid 1px white;
}

.container li:hover {
    cursor: pointer;
}

.switch {
    font-weight: 400;
}

.arrow {
    font-size: 1em;
    padding: 0 5px;
}



.close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 700;
}

.close svg {
    height: 25px;
    height: 25px;
    padding: 20px 20px 0 0;
    fill: white;
}

.close:hover {
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .title {
        font-size: 18px;
        padding-right: 10px;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100vw);
        opacity: 0
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(-50vw);
    }
    100% {
        transform: translateX(0);
    }
}