.container {
  padding-top: .5em;
}

.renewContainer {
  padding-bottom: .5em;
}

.openInNew, .pdf {
  fill: grey;
  vertical-align: middle;
}

.openInNew {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}

.pdf {
  width: 20px;
  height: 20px;
}

.title {
  color: var(--light-green);
  margin-bottom: .2em;
}

.subTitle {
  color: #333;
  margin: .2em;
}

.memberApplication {
  margin: 1.5em 0;
}

.applicationTitle {
  font-weight: 600;
}

.apply {
  display: block;
  padding: 5px 0;
}


  @media screen and (max-width: 1030px) {
    .applications {
      padding: .5em 0 1em 0;
      margin: 0;
      border-bottom: solid 1px lightgrey;
    }

    .memberContainer {
      border-bottom: none;
    }

    .renewContainer {
      padding: 1em 0 2em 0;
      border-bottom: solid 1px lightgrey;
    }

    .renewContainer h3 {
      margin-top: 0;
    }
  }