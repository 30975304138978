.table {
    font-family: 'Poppins', sans-serif;
}

.head tr {
    font-weight: 600;
    text-align: left;
}

.table th,
.table td {
    padding: 10px 15px;
}

.table tbody tr:nth-of-type(even) {
    background-color: var(--offwhite);
}

.title {
    text-align: left;
}

.register {
    padding: 7px 15px;
    background: var(--turqoise);
    color: white !important;
    border-radius: 3px;
    font-size: .9em;
}