.resources-title-container {
  background: url("../../assets/subpage-titles/resources-banner.jpg");
  background-size: cover;
  background-position: center;
}

.resources-regulatory {
  margin-top: 3em;
  padding-top: 3em;
  border-top: solid 3px var(--gold);
}

.general-box li:hover {
  color: var(--light-green);
}

.regulatory-box li:hover {
  color: var(--light-green);
}