.directory-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 50vh;
}

.member-search {
    margin-bottom: 0;
    font-weight: 500;
}

.member-reset-btn {
    border: solid 1px lightgrey;
    display: inline-block;
    padding: 2px 5px;
    margin: 0;
}

.directory-pagination-top {
    border-bottom: solid 1px lightgrey;
}

.directory-pagination-top {
    border-bottom: solid 1px lightgrey;
}
.directory-pagination-bottom {
    border-top: solid 1px lightgrey;
}

.directory-box {
    width: 215px;
    margin: 1em 1em 2em 1em;
    padding: 1em 0;
}

.dir-img {
    height: 95px;
    max-width: 100px;
    border-radius: 3px;
    object-fit: cover;
}

.dir-name {
    margin: 0;
}

.dir-specialty {
    margin: 0;
}

.dir-text {
    font-size: 15px;
    line-height: 1.5em;
}

.dir-website {
    color: var(--light-green);
    display: block;
    margin-top: 10px;
}

.dir-website:hover {
    color: var(--light-green);
}

@media screen and (min-width: 1350px) {
    .directory-box {
        margin: 1em 2em 2em 2em;
    }
}

@media screen and (max-width: 1030px) {
    .directory-pagination-top {
        padding-top: 1em;
    }
}

@media screen and (max-width: 530px) {
    .directory-container {
        justify-content: center;
        align-items: center;
    }

    .directory-box {
        width: 100%;
        padding-bottom: 2em;
        border-bottom: solid 1px lightgrey;
        text-align: center;
    }

    .directory-box:last-child {
        border-bottom: none;
    }
}