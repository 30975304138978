.content-cms {
    width: 80%;
    padding: 0 1em 0 2em;
    line-height: 2em;
    box-sizing: border-box;
    overflow: hidden;
}
  
.content-cms h1 {
    color: #595959;
}
.content-cms h2 {
    color: #595959;
}
.content-cms h3 {
    color: #595959;
}
.content-cms h4 {
    color: #595959;
}

.content-cms a {
    color: var(--light-green);
    font-weight: 600;
}

.content-cms button {
    border: solid 1px lightgrey;
}

.content-cms figure {
    margin: 0;
}

.content-cms table {
    width: 100%;
    margin: 2em 0;
    text-align: center;
    border-collapse: collapse;
}

.content-cms table th {
    background-color: var(--light-green);
    color: white;
    font-weight: 600;
}

.content-cms table tr:nth-child(even) {
    background-color: var(--offwhite);
}

.content-cms table td {
    padding: 1em;
    text-align: left;
    color: black;
}

@media screen and (max-width: 1030px) {
    .content-cms {
        width: 100%;
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .content-cms {
        width: 100%;
        padding: 0;
    }

    .content-cms table th {
        display: none;
    }

    .content-cms table tr {
        display: flex;
        flex-direction: column;
        margin: 15px;
    }

    .content-cms table tr td:first-child {
        background-color: var(--dark-blue);
        color: white;
    }
}