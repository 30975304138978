#history-logo {
    width: 150px;
    padding: 2em;
    float: right;
}

@media screen and (max-width: 1040px) {
    #history-logo {
        width: 150px;
        padding: 2em;
        float: unset;
        display: block;
        margin: auto;
    }
}