.banner-img {
  position: absolute;
  top: -43px;
  left: 0;
  width: 100%;
  padding-top: 43px;
  height: 550px;
  z-index: -1;
  object-fit: cover;
  object-position: 100% 30%;
}

.banner-active {
  opacity: 1;
  animation: slow-zoom 10s;
  transition: 2s;
}

.banner-inactive {
  opacity: 0;
  transition: 2s;
}

.hero-banner {
  position: relative;
  height: 550px;
  max-width: 1500px;
  margin: 130px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(to right, rgba(20, 20, 20, 0.45), transparent);
}

.progress-bar {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.39);
  animation: draw-line 10000ms linear;
}

.banner-title-container {
  align-self: flex-start;
  animation: fade-in 1s;
  padding: 4em 0 0 3em;
  color: white;
  z-index: 100;
  width: 50%;
  line-height: 5em;
  font-family: 'Poppins', sans-serif;
}

.mcds-title {
  font-size: 3.2em !important;
  line-height: 1.7em;
}

.banner-content {
  animation: fade-in 2s;
}

.banner-title {
  font-size: 2.8em;
  font-weight: 500;
  margin-top: 0;
}

.hero-btn {
  padding: .7em 1.5em;
  margin: 0 4em 0 0;
  font-size: 1em;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  color: white;
  background: var(--gold);
  backdrop-filter: blur(2px);
}

.hero-btn:hover {
  cursor: pointer;
}

.banner-buttons {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  color: white;
}

.banner-buttons:hover {
  cursor: pointer;
}

/* Animations */
@keyframes slow-zoom {
  0% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes draw-line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes open-banner {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Media Queries */
@media screen and (max-width: 1070px) {
  .hero-banner {
    margin-top: 75.81px;
  }
}

@media screen and (max-width: 850px) {
  .hero-banner {
    background: rgba(0,0,0, .55);
  }

  .banner-title-container {
    width: 100%;
    text-align: center;
    padding:  1em 10px 10px 10px;
    box-sizing: border-box;
  }

  .hero-btn {
    margin: 0 10px;
  }
}

@media screen and (max-width: 600px) {
  .banner-title {
    font-size: 3.3em;
  }

  .mcds-title {
    font-size: 3.5em;
  }
}

@media screen and (max-width: 500px) {
  .hero-banner{
    margin-top: 57.88px;
  }
}

@media screen and (max-width: 450px) {
  .banner-title {
    font-size: 2.2em;
    padding: 10px;
  }

  .mcds-title {
    font-size: 3.1em !important;
    line-height: 1.5em;
    padding: 10px;
  }

  .hero-btn {
    font-size: .8em;
  }

  .mcds-welcome {
    font-size: 3em;
  }
}
