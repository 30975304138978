.member-benefits-title-container {
    background: url("../../../assets/study.jpg");
      background-size: cover;
  }

.member-benefits-content ul {
    display: flex;
    flex-wrap: wrap;
}

.member-benefits-content li {
    width: 45%;
}