.sponsors {
    padding: 1em 100px;
}

.title {
    font-weight: 600;
    font-size: 1.8em;
    color: #595959;
    text-align: center;
    margin-bottom: 2em;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.sponsors li {
    margin: 0 1em;
}

.sponsors img {
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
}

.sponsors figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sponsors figcaption {
    margin: 1em 0;
}