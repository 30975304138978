.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    align-items: center;
    border-bottom: solid 1px lightgrey;
    background-color: white;
    box-sizing: border-box;
    z-index: 300;
    font-family: 'Poppins', sans-serif;
}

.logo {
    justify-self: center;
    grid-column: 1/2;
    grid-row: 1/4;
    padding: 10px;
    height: 130px;
    transition: height .3s;
}

.logo:hover {
    cursor: pointer;
}

.logoScrolled {
    height: 95px;
    transition: height .3s;
}

@media screen and (min-width: 1500px) {
    .header {
        padding: 0 3%;
    }
}

@media screen and (min-width: 1650px) {
    .header {
        padding: 0 5%;
    }
}

@media screen and (min-width: 1700px) {
    .header {
        padding: 0 8%;
    }
}

@media screen and (min-width: 1850px) {
    .header {
        padding: 0 10%;
    }
}