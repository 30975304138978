.find-a-dentist-title-container {
    background: url("../../../assets/subpage-titles/find-banner.jpg");
    background-size: cover;
    background-position: 100% 20%;
}

.find-a-dentist-content {
    gap: 2em;
    padding: 1em 50px 2em 50px;
    min-height: 100vh;
}

.pagination {
    grid-column: 2/3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-list {
    display: flex;
    justify-content: flex-end;
    margin: 0;
}

.pagination-list li {
    padding: .3em;
}

.pagination-list li:last-child {
    border: none;
}

.pagination-list li:hover {
    cursor: pointer;
    background-color: rgb(228, 228, 228);
}

.pagination-list-page {
    background: white;
}

.pagination-list-page-active {
    color: var(--turqoise);
    background: white;
}

/* DR BOXES */
.dr-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 0 0 4em;
}

.dr-box {
    display: flex; 
    justify-content: space-around;
    align-items: center;
    padding: 2em 0;
    width: 100%;
    /* max-height: 180px; */
    background-color: white;
    margin: .5em 0;
    line-height: 1.5em;
    border-bottom: solid 1px lightgrey;
    
}

.dr-img-wrapper {
    width: 15%;
    display: flex;
    justify-content: center;
}

.name-speciality {
    width: 30%;
    padding: 0 10px;
}

.address {
    width: 35%;
    font-size: 1em;
    font-weight: 300;
}

.second-address {
    display: block;
    margin-top: 1em;
    font-weight: 300;
}

.phone-website {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1em;
    font-weight: 300;
}

.dr-img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    /* object-position: 100% 20%; */
    border-radius: 5px;
}

.phone-fax svg {
    height: 17px;
    width: 17px;
    padding-right: 5px;
    vertical-align: middle;
}

.website-wrapper {
    text-align: center;
}

@media screen and (max-width: 1245px) {
    .dr-box {
        width: 100%;
        margin: 0;
    }

    .name-speciality {
        padding: 0;
    }
}

@media screen and (max-width: 800px){
    .dr-box {
        flex-direction: column;
        text-align: center;
    }

    .dr-image-wrapper, .address, .phone-website {
        width: 100%;
    }

    .name-speciality {
        width: 100%;
    }
    
    .speciality {
        margin: 0;
    }

    .phone {
        margin: 0 0 1em 0;
    }
}