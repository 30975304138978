.landing {
    height: 100vh;
    font-family: "Poppins", sans-serif;
}

.guests {
    height: 50%;
    background: linear-gradient(#27a2adc9, #27a2adc9), url("../../assets/family.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.members {
    height: 50%;
    background: linear-gradient(#F1B435c9, #f1b535c9), url("../../assets/dentist-working.jpg");
    background-position: center;
    background-size: cover;
    background-color: var(--gold);
    display: flex;
    justify-content: center;
    align-items: center;
}

.guestsTitle {
    font-size: 3.2em;
    color: white;
    font-weight: 500;
    margin-top: 2em;
}

.membersTitle {
    font-size: 3.2em;
    color: white;
    font-weight: 500;
    margin-bottom: 2em;
}

.logoWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo {
    width: 180px;
    background-color: white;
    padding: 15px 20px;
    border-radius: 42%;
    overflow: visible;
}

@media screen and (min-width: 1500px) {
    .logo {
        width: 220px;
    }
}