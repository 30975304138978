.announcements {
    grid-column: 1/3;
    padding: 1em 100px;
    background: #F6F4F3;
    overflow: hidden;
    position: relative;
}

.announcements-title {
    position: relative;
    font-weight: 600;
    font-size: 1.8em;
    color: #595959;
    text-align: center;
}

.announcements-content {
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.announcement-box {
    width: 290px;
    margin: .5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.announcement-title {
    margin: .7em 0;
    font-weight: 600;
    color: #333;
}

.announcement-content {
    line-height: 1.7em;
}

.announcement-content p {
    margin-top: 0;
}

.announcement-image {
    height: 185px;
    object-fit: cover;
}

.announcement-button {
    align-self: center;
    padding: .5em;
    font-size: .9em;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    background: var(--gold);
    color: white;
}

.announcement-button:hover {
    cursor: pointer;
}

.news-link-button {
    display: block;
    margin: 0 auto 2em auto;
    margin-top: 2em;
    height: 40px;
    width: 150px;
    background: transparent;
    border: solid 1px grey;
    color: black;
    font-size: .9em;
}

@media screen and (max-width: 600px) {
    .announcements {
        padding: 1em;
    }
}