.box {
    border-bottom: solid 1px lightgrey;
    padding: 1em 5px;
    font-family: 'Poppins', sans-serif;
}

.title {
    font-weight: 600;
    color: var(--light-green);
    margin: 0;
    padding: .7em 0;
}

.date {
    margin: .5em 0;
}