.make-post-sidebar h3 {
    color: var(--light-green);
}

.make-post-btn {
    background: transparent;
    border: solid 1px lightgrey;
    padding: .5em .6em;
}

.make-post-btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 1070px) {
    .make-post-sidebar {
        padding: 0 1em 0 0;
        margin: 0;
    }
}