.season-ticket-sidebar {
    padding-right: 1em;
}

.season-ticket-sidebar h3 {
    color: var(--light-green);
}

.season-ticket-sidebar .material-icons {
    color: grey;
    font-size: 16px;
    padding: 0 0 0 3px;
}

@media screen and (max-width: 1030px) {
    .season-ticket-sidebar {
        border-bottom: solid 1px lightgrey;
    }
}