.news-title-container {
    background: url("../../assets/subpage-titles/news-banner.jpg");
    background-size: cover;
}

.news-feed-container:hover {
    cursor: pointer;
}

.news-feed-container > .active {
    background-color: var(--offwhite);
}

.news-feed-item {
    padding: 1.5em 5px;
    border-bottom: solid 1px var(--turqoise);
}

.news-feed-title {
    color: var(--light-green);
    font-weight: 600;
    margin-top: 0;
}

.news-feed-date {
    color: grey;
    font-size: .8em;
    margin-bottom: 0;
}

@media screen and (max-width: 1030px) {
    .news-subpage {
        display: flex;
        flex-direction: column-reverse;
    }
}