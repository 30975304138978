input[type=submit] {
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
}

.contact-form-container {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
}

#contact-form {
    display: flex;
    flex-direction: column;
}

#name {
    height: 25px;
    margin: .3em 0;
    font-size: 15px;
    border: solid 1px lightgrey;
    padding: 0 0 0 5px;
}

#email {
    height: 25px;
    margin: .3em 0;
    font-size: 15px;
    border: solid 1px lightgrey;
    padding: 0 0 0 5px;
}

#message {
    height: 60px;
    margin: .3em 0;
    font-size: 15px;
    border: solid 1px lightgrey;
    font-family: 'Poppins', sans-serif;
    padding: 0 0 0 5px;
}

#send {
    padding: 4px 7px;
    font-size: 16px;
    font-weight: 600;
    align-self: flex-end;
    background-color: var(--gold);
    border-radius: 5px;
    margin-top: 5px;
    color: white;
    border: none;
}

#send:hover {
    color: black;
}

.home-contact {
    grid-column: 1/3;
    padding: 3em 10%;
    background-color: var(--offwhite);
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}

.home-contact-content {
    display: flex;
    justify-content: space-between;
}

.home-contact-affiliates img {
    height: 50px;
    margin: 1em 1em 1em 0;
}

.contact-info {
    line-height: 1.5em;
}

@media screen and (max-width: 850px) {
    .home-contact-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .contact-form-container {
        width: 50%;
    }
}

@media screen and (max-width: 575px) {
    .contact-form-container {
        width: 75%;
    }
}


