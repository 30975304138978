.container {
    grid-column: 3/4;
    grid-row: 1/2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
  
.loginWrapper {
    display: flex;
    align-items: center;
    color: var(--red);
    font-size: 14px;
}

.loginLink {
    font-weight: 500;   
}

.loginWrapper svg {
    fill: var(--red);
    width: 17px;
    height: 17px;
    margin-right: 8px;
    margin-bottom: 2px
}

.version {
    color: #595959;
    padding: 1em 2em 1em 1.5em;
    font-size: 14px;
}
  
.versionActive {
    font-weight: 500;
    color: var(--light-green);
}

.pipe {
    color: lightgrey;
}