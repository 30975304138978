.main {
  margin: 0 auto;
  min-height: 100vh;
  margin-top: 107px;
  max-width: 1500px;
}

@media screen and (max-width: 1070px) {
  .main{
    margin-top: 75.81px;
  }
}

@media screen and (max-width: 500px) {
  .main{
    margin-top: 57.88px;
  }
}


